import { MayBeNull } from '@wpp-open/core'

export enum ACTION_ANALYTICS {
  ACTION_PAGE_PROJECTS = 'action_page_projects',
  ACTION_PROJECT_CREATE = 'action_project_create',
  ACTION_PROJECT_WORKFLOW_VIEW = 'action_project_workflow_view',
  ACTION_PROJECT_EDIT = 'action_project_edit',
  ACTION_PROJECT_EDIT_CANCELLED = 'action_project_edit_cancelled',
  ACTION_PROJECT_WRIKE_CONNECTED = 'action_project_wrike_connected',
  ACTION_PROJECT_WRIKE_DISCONNECTED = 'action_project_wrike_disconnected',
  ACTION_PROJECT_HIERARCHY_CHANGED = 'action_project_hierarchy_changed',
  ACTION_PROJECT_PROCESS_TYPE_CHANGED = 'action_project_process_type_changed',
  APPLICATION_LAUNCH = 'app_launch',
  ACTION_CANVAS_REDIRECT = 'action_canvas_redirect',
  ACTION_WORKFLOW_ITEM_ADD = 'action_workflow_item_add',
  ACTION_WORKFLOW_ITEM_MOVE = 'action_workflow_item_move',
  ACTION_WORKFLOW_ITEM_DELETE = 'action_workflow_item_delete',
  ACTION_WORKFLOW_CONNECTION_REMOVED = 'action_workflow_connection_removed',
  ACTION_WORKFLOW_CONNECTION_ADDED = 'action_workflow_connection_added',
  ACTION_WORKFLOW_ITEM_ASSIGN = 'action_workflow_item_assign',
  ACTION_WORKFLOW_ITEM_DATES_EDIT = 'action_workflow_item_dates_edit',
  ACTION_WORKFLOW_ITEM_VISIBILITY_CHANGE = 'action_workflow_item_visibility_change',
  ACTION_WORKFLOW_ITEM_STATUS_CHANGE = 'action_workflow_item_status_change',
  ACTION_WORKFLOW_ITEM_EDIT = 'action_workflow_item_edit',
  ACTION_WORKFLOW_ITEM_EDIT_CANCELLED = 'action_workflow_item_edit_cancelled',
  ACTION_VIEW_PROJECT_DETAILS = 'action_view_project_details',
  ACTION_VIEW_PROJECT_CANVAS = 'action_view_project_canvas',
  ACTION_PROJECTS_VIEW = 'action_projects_view',
  ACTION_PROJECT_CREATE_CANCELLED = 'action_project_create_cancelled',
  ACTION_VIEW_PROJECT_ABOUT = 'action_view_project_about',
  ACTION_VIEW_TEMPLATES = 'action_view_templates',
  ACTION_TEMPLATE_EDIT = 'action_template_edit',
  ACTION_TEMPLATE_CREATE_CANCELLED = 'action_template_create_cancelled',
  ACTION_TEMPLATE_DELETE = 'action_template_delete',
  ACTION_TEMPLATE_CREATE = 'action_template_create',
  ACTION_VIEW_WORKFLOW_TEMPLATE = 'action_view_workflow_template',
}

// Type to extract workflow-related keys from ACTION_ANALYTICS
type WorkflowActionKeys = keyof typeof ACTION_ANALYTICS extends infer K
  ? K extends `ACTION_WORKFLOW_${string}`
    ? K
    : never
  : never

// Create a union type of the filtered workflow action values
export type WorkflowActionType = (typeof ACTION_ANALYTICS)[WorkflowActionKeys]

// TODO should be replaced with LaunchLocations enum from `@wpp/core` when core will release >6.0.0 version.
export enum LAUNCH_LOCATIONS {
  NavigationMenu = 'Navigation Menu',
  Breadcrumbs = 'Breadcrumbs',
  ProjectWorkflow = 'Project Workflow',
  Favorites = 'Favorites',
  HamburgerMenu = 'Hamburger Menu',
}

// TODO should be removed when core will release >6.0.0 version.
export type AnalyticsTrackExternalLinkLaunchHandler = (params: {
  hierarchyNodeId: MayBeNull<string>
  launchedFrom?: string
  productName?: string
  productOwnerEmail?: string
  productCommercialModel?: string
  productCategory?: string
  productSubcategory?: string
  appId?: string
  appInstanceId?: string
  appInstanceName?: string
}) => void

export enum CanvasItemType {
  ACTIVITY = 'activity',
  PHASE = 'phase',
  APPLICATION = 'application',
  FILE = 'file',
  LINK = 'link',
}

export enum EventPageSource {
  CANVAS = 'canvas',
  WORKFLOW = 'workflow',
  TIMELINE = 'timeline',
}

export const ANALYTICS_SOURCE_ORCHESTRATION = 'Orchestration'
